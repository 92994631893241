import {format, parseISO} from 'date-fns'
//date: Date | string
export const formatDate = (date, dateFormat) => {
    if (!dateFormat) {
        dateFormat = "MMM, dd yyyy"
    }
    if (typeof date === 'string') {
        date = parseISO(date)
    }
    return format(date, dateFormat)
}

//date: Date | string
export const formatTime = (date) => {
    if (typeof date === 'string') {
        date = new Date(date)
    }
    // Create an Intl.DateTimeFormat instance for the time
    const timeFormatter = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',   // Numeric hour
        minute: '2-digit', // Minute with leading zero if necessary
        hour12: true       // 12-hour time with AM/PM
    });
    // Format the time according to the specified options
    return timeFormatter.format(date).toLowerCase(); // Convert to lowercase to match your example (AM/PM)
}

//date: Date | string
export const formatWeekDay = (date) => {
    if (typeof date === 'string') {
        date = new Date(date)
    }
    return format(date, 'EEEE, MMMM dd')
}